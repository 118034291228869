import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import NavBar from "@Components/Header";
import Footer from "@Components/Footer";

import PagePreloader from "./components/PagePreloader";
import MessageOnlyMobile from "./components/MessageOnlyMobile";

import routes from "./routes";
import scrollToTop from "./utils/scrollToTop";
import Main from "@Pages/Main";
import runOneSignal from './utils/oneSignal.js';
import { AuthProvider } from '@/contexts/AuthContext';

const App = () => {
  const isMobilePath = window.location.search.includes('mobile=true');

  const ScrollToTopOnPageChange = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      if (window.scrollY > 0) scrollToTop();
    }, [pathname]);

    const oneSignalsDomainNames = ['doctorsol.pe'];
    useEffect(() => {
      if (oneSignalsDomainNames.includes(process.env.REACT_APP_PROJECT_CODE) ) {
        runOneSignal();
      }
    })
  };

  // test Chat-bot (test-web.doctorsol.pe)
  useEffect(() => {
    if (process.env.REACT_APP_PROJECT_CODE === 'test-web.doctorsol.pe') {
      const script = document.createElement('script');
      script.src = 'https://cdn.pulse.is/livechat/loader.js';
      script.async = true;
      script.setAttribute('data-live-chat-id', '66a7a2990286de5d270efd8a');
      document.body.appendChild(script);
    }
  }, []);

  const onlyMobileDomainNames = ['drsol.pe', 'test-web.drsol.pe'];
  if (onlyMobileDomainNames.includes(process.env.REACT_APP_PROJECT_CODE) && !isMobilePath) {
    return <MessageOnlyMobile />;
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <ScrollToTopOnPageChange />
        <NavBar />
        <Routes>
          <Route path="/" element={<Main />} />
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={
              <Suspense fallback={<PagePreloader />}>
                {element}
              </Suspense>
            } />
          ))}        
        </Routes>
        <Footer />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
