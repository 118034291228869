import React from "react";

import "./styles.scss";

import image1 from "@assets/images/01.svg";
import image2 from "@assets/images/02.svg";
import image3 from "@assets/images/03.svg";
import girlPicture from "@assets/images/prestamo-personal-online-instante-doctorsol.png";
import girlPictureWebp from "@assets/images/prestamo-personal-online-instante-doctorsol.webp";


const ComoFuncionaSection = () => {
  return (
    <section className="container-width-100">
      <div className="block-2">
        <div className="container-page">

          <div className="block-2-main-container">
            <h2 className="title-block color-white">¿Cómo Funciona Nuestro Proceso de Préstamo?</h2>
            <div className="block-2-description">
              <div className="column-information">
                <img alt="information" src={image1} />
                <div>
                  <p className="title-column">Solicitud</p>

                  <p className="description-column">Solo necesitas tu teléfono,
                    DNI, Correo electrónico y una cuenta bancaria a tu nombre</p>
                </div>

              </div>
              <div className="column-information">
                <img alt="information" src={image2} />
                <div>
                  <p className="title-column">Respuesta Inmediata</p>
                  <p className="description-column">Recibirás un mensaje de texto con la respuesta a tu solicitud al número que registraste</p>
                </div>

              </div>
              <div className="column-information">
                <img alt="information" src={image3} />
                <div>
                  <p className="title-column">Desembolso 100% Online</p>
                  <p className="description-column">El monto aprobado se transferirá a tu cuenta de ahorros</p>
                </div>

              </div>
            </div>
            <picture>
              <source srcSet={girlPictureWebp} type="image/webp"/>
              <source srcSet={girlPicture} type="image/png" />
              <img alt="Pareja sonriendo por recibir un préstamo personal online" className="abs-position-img" src={girlPicture} />
            </picture>
          </div>
          <div className="breakpoint-1" id="block-32"></div>
        </div>
      </div>
    </section>
  );
};

export default ComoFuncionaSection;
