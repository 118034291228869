import React, { useEffect, useMemo, useState } from "react";
import Slider from "react-slider";

import "./styles.scss";
import GreenButton from "@Common/GreenButton";
import Loader from "@Common/Loader";
import InitLoader from "./InitLoader";
import {
  getCalculatedDataByParams,
  getInitialCalcData,
  getRedirectToServiceLinkByParams,
} from "@services/api";

import { useAuthState } from '@/contexts/AuthContext';

import formatPriceToMx from "@utils/formatPriceToMx";

import bgMan from "@assets/images/interes-en-tu-primer-prestamo.png";

const defaultCalculatorInfo = {
  interestRate: 0.0,
  allFeeSum: 0,
  loanPrinciple: 0,
  LoanTenor: 0,
  vat: 0,
  organizationalFee: 0,
  paymentDt: "",
  PlatformFee: 0,
  PlatformFeeNoDiscount: 0,
  totalToPay: 0.0,
};

const CalculatorSection = React.forwardRef((props, ref) => {
  const { isLoading } = useAuthState();

  const [isFetching, setIsFetching] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [minSum, setMinSum] = useState(0);
  const [maxSum, setMaxSum] = useState(10000);
  const [minTerm, setMinTerm] = useState(7);
  const [maxTerm, setMaxTerm] = useState(30);
  const [currentAmount, setCurrentAmount] = useState(3000);
  const [currentTerm, setCurrentTerm] = useState(14);
  const [amountStep, setAmountStep] = useState(1000);
  const [showAmountDetails, setShowAmountDetails] = useState(false);
  const [calculatorInfo, setCalculatorInfo] = useState(defaultCalculatorInfo);

  const paymentDate = useMemo(() => {
    return new Date(calculatorInfo?.paymentDt).toLocaleDateString( 'en-GB' );
  }, [calculatorInfo.paymentDt]);

  const redirectToServicePage = async (e) => {
    // _paq.push(['trackEvent', 'Event', 'Button', 'Begin']); sendOrder()
    if (isFetching || !initialized) return;
    e.preventDefault();
    setIsFetching(true);
    try {
      const { link } = await getRedirectToServiceLinkByParams({ Amount: currentAmount, Term: currentTerm });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
      'event':'Begin_Registration'
      });
      window.location.assign(link);
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetching(false);
    }
  };


  const getInitialData = async () => {
    try {
      const data = await getInitialCalcData();
      setAmountStep(data.amountStep);
      setMinSum(data.amounts.minSum);
      setMaxSum(data.amounts.maxSum);
      setCurrentAmount(data.defaultAmount);
      setMinTerm(data.terms.minTerm);
      setMaxTerm(data.terms.maxTerm);
      setCurrentTerm(data.defaultTerm);
    } catch (e) {
      console.error(e);
    } finally {
      setInitialized(true);
    }
  };

  const getCalculatedData = async () => {
    setIsFetching(true);
    try {
      const data = await getCalculatedDataByParams({
        Amount: currentAmount,
        Term: currentTerm,
      });

      setCalculatorInfo((prev) => ({
        ...prev,
        ...data,
      }));
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetching(false);
    }
  };

  const toggleShowAmountDetails = () => {
    setShowAmountDetails(prev => !prev);
  };

  useEffect(() => {
    if ( !isLoading ) {
      getInitialData();
    }
  }, [isLoading]);

  useEffect(() => {
    if (initialized) getCalculatedData();
  }, [initialized]);

  return (
    <section ref={ref} className="container-width-100 ">
      <div className="block-1 container-page" id="block-1">
        <div className="bg-man">
          <img src={bgMan} alt="Persona sonriente con dinero en efectivo y un celular" />
        </div>
        <div className="calculator-slider">
          {!initialized ? <InitLoader /> : (
            <>
              <h1>Préstamo Personal Online al Instante: ¡Rápido, Fácil y Seguro con DoctorSol!</h1>
              <h3 className="title">Solicita un préstamo en línea, las 24 horas desde cualquier parte del Perú</h3>
              <div className="calculator">

                <div className="calculator__sum">
                  <div className="calculator__sum__text-top">
                    <p className="body_m-bold">Elije la cantidad</p>
                    <p
                      className="body_l-bold"
                      id="calculator__sum"
                    >
                      {formatPriceToMx(currentAmount)}
                    </p>
                  </div>

                  <Slider
                    className="calculator-custom-slider"
                    trackClassName="calculator-custom-slider-track"
                    thumbClassName="calculator-custom-slider-thumb"
                    min={minSum}
                    max={maxSum}
                    step={amountStep}
                    defaultValue={currentAmount}
                    value={currentAmount}
                    onChange={(val) => setCurrentAmount(val)}
                    onAfterChange={(v) => getCalculatedData()}
                  />

                  <div className="body_s-regular calculator__sum__text-bottom">
                    <p id="calculator__sum-pip-left">{formatPriceToMx(minSum)}</p>
                    <p id="calculator__sum-pip-right">{formatPriceToMx(maxSum)}</p>
                  </div>
                  <p className={`calculator__sum__prompt ${ currentAmount <= 200 ? 'hidden' : '' }`} >
                    Más de S/200 a partir de tu 2do préstamo
                  </p>
                </div>

                <div className="calculator__sum">
                  <div className="calculator__sum__text-top">
                    <p className="body_m-bold">Elije el plazo</p>
                    <p className="body_l-bold" id="calculator__day">{`${currentTerm || "--"} días`}</p>
                  </div>

                  <Slider
                    className="calculator-custom-slider"
                    trackClassName="calculator-custom-slider-track"
                    thumbClassName="calculator-custom-slider-thumb"
                    min={minTerm}
                    max={maxTerm}
                    step={1}
                    defaultValue={currentTerm}
                    value={currentTerm}
                    onChange={(val) => setCurrentTerm(val)}
                    onAfterChange={(v) => getCalculatedData()}
                  />

                  <div className="body_s-regular calculator__sum__text-bottom">
                    <p id="calculator__day-pip-left">{`${minTerm || "-"} días`}</p>
                    <p id="calculator__day-pip-right">{`${maxTerm || "--"} días`}</p>
                  </div>
                </div>

                <div className="calculator__info text-center">
                  {isFetching && 
                  <div className={`calculator__info__loader ${showAmountDetails ? '' : 'collapsed'}`} >
                    <Loader />
                  </div> }
                  {!isFetching && (
                    <>
                      <p className="total-label">
                        <span className="body_m-bold">
                          Total a Pagar
                        </span>
                        <span id="PaymentDt">
                          { ' ' + paymentDate || "----"}
                        </span>
                      </p>

                      <button
                        className="button-amount body_xl-bold"
                        onClick={() => toggleShowAmountDetails()}
                      >
                        <span id="TotalToPay">
                          {formatPriceToMx(calculatorInfo?.totalToPay)}
                        </span>
                        <svg className="arrow-amount-detail" width="14" height="8" viewBox="0 0 14 8" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 8C6.7 8 6.5 7.9 6.3 7.7L0.3 1.7C-0.1 1.3 -0.1 0.7 0.3 0.3C0.7 -0.1 1.3 -0.1 1.7 0.3L7 5.6L12.3 0.3C12.7 -0.1 13.3 -0.1 13.7 0.3C14.1 0.7 14.1 1.3 13.7 1.7L7.7 7.7C7.5 7.9 7.3 8 7 8Z"
                            fill="#A7A7A7" />
                        </svg>
                      </button>
                      <div className={`amount-detalis ${showAmountDetails ? "active" : ""}`}>
                        <div className="amount-container">
                          <div className=" calculator_column-left">
                            <ul>
                              <li className="body_m-regular">Monto principal</li>
                              <li className="body_m-bold body_m-bold-black" id="loanPrinciple">
                                {formatPriceToMx(calculatorInfo?.loanPrinciple)}
                              </li>
                            </ul>
                            <ul>
                              <li className="body_m-regular">Interés</li>
                              <li className="body_m-bold body_m-bold-black" id="interestRate">
                                {formatPriceToMx(calculatorInfo?.interestRate)}
                              </li>
                            </ul>

                            {/* <!--  <ul className="more-visiable">
                            <li className="body_m-regular"></li>
                            <li className="body_m-bold" id=""></li>
                            </ul>--> */}
                          </div>

                          <div className=" calculator_column-right">
                            <ul>
                              <li className="body_m-regular">
                                Comisión de Administración
                              </li>
                              <li className="body_m-bold body_m-bold-black" id="organizationalFee">
                                {formatPriceToMx(calculatorInfo?.organizationalFee)}
                              </li>
                            </ul>
                            <ul>
                              <li className="body_m-regular">
                                IGV
                              </li>
                              <li className="body_m-bold body_m-bold-black" id="vat">
                                18%
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p className="body_m-regular">
                          TCEA: 461%, TCEM: 38,42%
                        </p>
                      </div>
                    </>
                  )}

                  <div className="calculator__button body_m-extrabold">
                    <GreenButton
                      disabled={isFetching}
                      onClick={(e) => redirectToServicePage(e)}
                    >
                      Solicita tu Crédito
                    </GreenButton>
                  </div>
                </div>
              </div>
            </>
          )}

        </div>
        <div className="error-message" id="calc-error">
          <p></p>
        </div>
        <div className="breakpoint-1" id="block-3"></div>
      </div>
    </section>
  );
});

export default CalculatorSection;
